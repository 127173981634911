import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/SiteLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "numbers"
    }}>{`Numbers`}</h1>
    <p>{`Coming soon...`}</p>
    <h2 {...{
      "id": "integers-vs-floats"
    }}>{`Integers vs "Floats"`}</h2>
    <p>{`TODO: This... Just a circle example?`}</p>
    <h2 {...{
      "id": "infinity-and-beyond"
    }}>{`Infinity and Beyond`}</h2>
    <p>{`TODO: This...`}</p>
    <h2 {...{
      "id": "arithmetic-operators"
    }}>{`Arithmetic Operators`}</h2>
    <p>{`TODO: This... circles where we change the size. Addition, subtraction, multiplication, division?`}</p>
    <h2 {...{
      "id": "grouping-expressions"
    }}>{`Grouping Expressions`}</h2>
    <p>{`TODO: Order of operations and whatnot.`}</p>
    <h2 {...{
      "id": "the-modulus-operator"
    }}>{`The Modulus Operator`}</h2>
    <p>{`TODO: Even/odd example.`}</p>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <p>{`TODO: Come up with a good example...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      